<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>登记管理</el-breadcrumb-item>
        <el-breadcrumb-item>登记列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <el-tabs class="mt10" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="item in editableTabs"
          :key="item.name"
          :label="item.title"
          :name="item.name"
        >
          <div class="pt20" style="text-align: left">
            <el-form :inline="true" :model="page" class="demo-form-inline">
              <el-form-item>
                <el-input
                  placeholder="企业名称、经营者"
                  v-model="page.company_name"
                  clearable
                  @clear="onSubmit"
                >
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="page.business_type"
                  clearable
                  placeholder="业务类型"
                  @change="onSubmit(page.business_type)"
                >
                  <el-option
                    v-for="v in list_btn3"
                    :key="v.id"
                    :label="v.title"
                    :value="v.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="page.business_state"
                  clearable
                  placeholder="营业状态"
                  @change="onSubmit(page.business_state)"
                >
                  <el-option
                    v-for="v in list_btn1"
                    :key="v.id"
                    :label="v.title"
                    :value="v.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  v-if="activeName == 3"
                  type="date"
                  placeholder="开始日期（归档）"
                  v-model="page.search_time_bigen"
                  :picker-options="pickerOptionsStart"
                  style="width: 100%"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  @change="change(6, page.search_time_bigen)"
                ></el-date-picker>
                <el-date-picker
                  v-if="activeName == 4"
                  type="date"
                  placeholder="开始日期（驳回）"
                  v-model="page.search_time_bigen"
                  :picker-options="pickerOptionsStart"
                  style="width: 100%"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  @change="change(6, page.search_time_bigen)"
                ></el-date-picker>
                <el-date-picker
                  v-if="activeName == 2"
                  type="date"
                  placeholder="开始日期（登记）"
                  v-model="page.search_time_bigen"
                  :picker-options="pickerOptionsStart"
                  style="width: 100%"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  @change="change(6, page.search_time_bigen)"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  v-if="activeName == 3"
                  type="date"
                  placeholder="结束日期（归档）"
                  v-model="page.search_time_end"
                  :picker-options="pickerOptionsEnd"
                  style="width: 100%"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  @change="change(7, page.search_time_end)"
                ></el-date-picker>
                <el-date-picker
                  v-if="activeName == 4"
                  type="date"
                  placeholder="结束日期（驳回）"
                  v-model="page.search_time_end"
                  :picker-options="pickerOptionsEnd"
                  style="width: 100%"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  @change="change(7, page.search_time_end)"
                ></el-date-picker>
                <el-date-picker
                  v-if="activeName == 2"
                  type="date"
                  placeholder="结束日期（登记）"
                  v-model="page.search_time_end"
                  :picker-options="pickerOptionsEnd"
                  style="width: 100%"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  @change="change(7, page.search_time_end)"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
          <!-- <el-row class="box search">
       <el-form :inline="true"  :model="page" class="demo-form-inline">
         
        <el-col :span="4" class="mr10">
             <el-form-item size="small" >
         <el-input placeholder="企业名称、经营者" v-model="page.company_name" clearable class="input-with-select" @clear="onSubmit">
       
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="4" class="mr10">
           <el-form-item size="small">
          <el-select v-model="page.business_type" clearable placeholder="业务类型" @change="onSubmit(page.business_type)">
            <el-option v-for="v in list_btn3" :key="v.id" :label="v.title" :value="v.id"></el-option>
          </el-select>
        </el-form-item>
        </el-col>
       
        <el-col v-if="activeName==3" :span="4" class="mr10">
           <el-form-item size="small">
              <el-select v-model="page.business_state" clearable placeholder="营业状态" @change="onSubmit(page.business_state)">
            <el-option v-for="v in list_btn1" :key="v.id" :label="v.title" :value="v.id"></el-option>
          </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="8" class="mr10">
          <el-form-item size="small">
            <el-col class="line" :span="11">
            <el-date-picker v-if="activeName==3" type="date" placeholder="开始日期（归档）" v-model="page.search_time_bigen"  :picker-options="pickerOptionsStart" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="change(6,page.search_time_bigen)"></el-date-picker>
            <el-date-picker v-if="activeName==4" type="date" placeholder="开始日期（驳回）" v-model="page.search_time_bigen"  :picker-options="pickerOptionsStart" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="change(6,page.search_time_bigen)"></el-date-picker>
            <el-date-picker v-if="activeName==2" type="date" placeholder="开始日期（登记）" v-model="page.search_time_bigen"  :picker-options="pickerOptionsStart" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="change(6,page.search_time_bigen)"></el-date-picker>
           </el-col>
            <el-col class="line" :span="2">至</el-col>
           <el-col class="line" :span="11">
            <el-date-picker  v-if="activeName==3" type="date" placeholder="结束日期（归档）" v-model="page.search_time_end" :picker-options="pickerOptionsEnd" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="change(7,page.search_time_end)"></el-date-picker>
            <el-date-picker v-if="activeName==4" type="date" placeholder="结束日期（驳回）" v-model="page.search_time_end" :picker-options="pickerOptionsEnd" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="change(7,page.search_time_end)"></el-date-picker>
            <el-date-picker v-if="activeName==2" type="date" placeholder="结束日期（登记）" v-model="page.search_time_end" :picker-options="pickerOptionsEnd" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="change(7,page.search_time_end)"></el-date-picker>
           </el-col>
        </el-form-item>
        </el-col>
        <el-col :span="3" class="tl">
           <el-form-item  size="small">
          <el-button  type="primary"  @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
     
        </el-form-item>
        </el-col>
       
      </el-form>
    </el-row> -->
          <div class="box list">
            <div v-if="page.filing_state == '3'" class="tl list_tab">
              <div v-for="(v, k) in list_btn4" :key="k" class="pr disib mr10">
                <el-button
                  :class="activeClass == k ? 'active' : ''"
                  size="small"
                  @click="IsActive(k, v.id)"
                  >{{ v.title }}
                  <span
                    v-if="v.id == '2'"
                    class="list_tag pa disib bgcred cfff"
                    >{{ expired }}</span
                  ><span
                    v-if="v.id == '4'"
                    class="list_tag pa disib bgc666 cfff"
                    >{{ will_expire }}</span
                  ></el-button
                >
              </div>
            </div>
            <el-table border :data="tableData" style="width: 100%">
              <el-table-column
                type="index"
                align="center"
                width="60"
                label="序号"
              ></el-table-column>
              <el-table-column
                prop="companytag_code"
                min-width="150"
                :show-overflow-tooltip="true"
                label="主体类型"
                align="center"
                :formatter="userTypeList3"
              ></el-table-column>
              <el-table-column
                prop="company_name"
                min-width="150"
                :show-overflow-tooltip="true"
                label="企业名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="legal_person_name"
                min-width="100"
                :show-overflow-tooltip="true"
                label="经营者"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="mobilephone"
                min-width="100"
                :show-overflow-tooltip="true"
                label="手机号"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="business_type"
                min-width="100"
                :show-overflow-tooltip="true"
                align="center"
                label="业务类型"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.business_type == 1">首次</span>
                  <span
                    v-else-if="scope.row.business_type == 2"
                    style="color: #9d9d9d"
                    >延续</span
                  >
                  <span v-else-if="scope.row.business_type == 3" class="red"
                    >注销</span
                  >
                  <span v-else style="color: #faa500">变更</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="business_state"
                label="营业状态"
                min-width="100"
                :show-overflow-tooltip="true"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.business_state == 1">正常营业</span>
                  <span v-else-if="scope.row.business_state == 2" class="red"
                    >暂停营业</span
                  >
                  <span
                    v-else-if="scope.row.business_state == 3"
                    style="color: #9d9d9d"
                    >停止营业</span
                  >
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                class-name="aa"
                fixed="right"
                min-width="150"
                align="center"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    class="general_color disib"
                    size="mini"
                    @click="godetail(scope.row.company_id)"
                    >详情</el-button
                  >
                  <el-button
                    v-if="activeName != 4"
                    type="text"
                    class="general_color disib"
                    size="mini"
                    @click="goLog(scope.row.company_id)"
                    >日志</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <div class="i-page fr disib mt20">
              <el-pagination
                background
                :page-size="10"
                layout="total,prev, pager, next"
                :total="total"
                :current-page="currentPage"
                @current-change="currentChange"
              >
              </el-pagination>
            </div>
            <el-dialog
              title="登记延续"
              :visible.sync="timeVisible"
              :modal-append-to-body="false"
              width="25%"
              :show-close="false"
            >
              <el-form ref="formInline" :model="formInline">
                <span>延续主体：{{ c_name }}</span>
                <el-form-item
                  label="选择延续至："
                  prop="unuseful_time"
                  :rules="[
                    {
                      required: true,
                      message: '请选择时间',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-date-picker
                    type="date"
                    clearable
                    placeholder="选择延续至"
                    v-model="formInline.unuseful_time"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button @click="timeVisible = false">取 消</el-button>
                  <el-button type="primary" @click="continueTime('formInline')"
                    >确 定</el-button
                  >
                </el-form-item>
              </el-form>
            </el-dialog>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../../util/api";
import { local, aa, session } from "../../../../util/util";
import Globle from "@/components/Globle";
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: {
        pager_offset: "0",
        company_name: "",
        expire_state: "",
        filing_state: "3",
        business_state: "",
        business_type: "",
      },
      user: "",
      c_name: "",
      formInline: {
        unuseful_time: "",
      },
      timeVisible: false,
      timer: "",
      loading: false,
      options4: "",
      options2: "",
      menu_btns: Globle.menu_btns,
      activeClass: 0,
      editableTabs: [
        { title: "待审核", name: "2" },
        { title: "已归档", name: "3" },
        { title: "已驳回", name: "4" },
      ],
      list_btn1: [
        { id: "1", title: "正常营业" },
        { id: "2", title: "暂停营业" },
        { id: "3", title: "停止营业" },
      ],
      list_btn3: [
        { id: "1", title: "首次" },
        { id: "2", title: "延续" },
        { id: "3", title: "注销" },
        { id: "4", title: "变更" },
      ],
      list_btn4: [
        { id: "1", title: "正常" },
        { id: "2", title: "过期" },
        { id: "3", title: "注销" },
        { id: "4", title: "即将过期" },
      ],
      currentPage: 1,
      total: 0,
      expired: "",
      will_expire: "",
      tableData: [],
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.page.search_time_end) {
            return (
              time.getTime() > new Date(this.page.search_time_end).getTime()
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
    };
  },
  computed: {
    ...mapState({
      recordPage4: (state) => state.record.recordPage4,
    }),
  },
  created() {
    var that = this;
    that.user = local.get("user");
    that.page = Object.assign({}, that.recordPage4);
    that.activeName = String(that.page.filing_state);
    that.activeClass = String(that.page.expire_state) - 1;
    that.currentPage = that.page.pager_offset / 10 + 1;
    that.init(that.page);
  },
  methods: {
    userTypeList(row) {
      return row.place_area + "平方米";
    },
    userTypeList3(row) {
      if (row.company_tag_id == "17") {
        return "第三方检测单位";
      }
    },
    handleClick() {
      var that = this;
      if (that.activeName == 2) {
        that.page.filing_state = "2";
      } else if (that.activeName == 3) {
        that.page.filing_state = "3";
      } else if (that.activeName == 4) {
        that.page.filing_state = "4";
      }

      this.onSubmit();
    },
    init(params) {
      var that = this;
      that.page.filing_state = String(that.page.filing_state);
      that.page.expire_state = String(that.page.expire_state);
      axios
        .get("/pc/company-third-party-unit/list", params)
        .then((v) => {
          that.will_expire = v.data.will_expire;
          that.expired = v.data.expired;
          that.tableData = v.data.company_list;
          that.total = v.data.pager_count;
        })
        .catch(() => {});
    },
    change(index, val) {
      var that = this;
      if (val == null) val = "";
      if (index == 6) {
        that.page.search_time_bigen = val;
      }
      if (index == 7) {
        that.page.search_time_end = val;
      }

      this.onSubmit();
    },
    // 搜索
    onSubmit() {
      var that = this;
      that.currentPage = 1;
      that.page.pager_offset = "0";
      if (this.page.search_time_end == null) {
        this.page.search_time_end = "";
      }
      if (this.page.search_time_bigen == null) {
        this.page.search_time_bigen = "";
      }
      that.$store.dispatch("record/setNewPage4", that.page);
      that.init(that.page);
    },
    // 分页
    currentChange(page) {
      var that = this;
      that.currentPage = page;

      that.page.pager_offset = String((page - 1) * 10);
      that.$store.dispatch("record/setNewPage4", that.page);
      that.init(that.page);
    },
    godetail(index) {
      session.set("params", { id: index, name: "" });
      this.$router.push({
        name: "TestDetail",
      });
    },
    goLog(index) {
      session.set("params", { id: index, name: "" });
      this.$router.push({
        name: "TestLog",
      });
    },
    IsActive(k, val) {
      var that = this;
      this.activeClass = k;
      this.page.expire_state = val;
      this.onSubmit();
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #409eff;
    border-color: #409eff;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
